// extracted by mini-css-extract-plugin
export var blink = "styles-module--blink--xmGnm";
export var content = "styles-module--content--7YfLp";
export var left_right_column_back = "styles-module--left_right_column_back--JgPTq";
export var left_right_column_front = "styles-module--left_right_column_front--g4E4g";
export var page = "styles-module--page--U8wl5";
export var plain_text_section_back = "styles-module--plain_text_section_back--dqer0";
export var plain_text_section_description = "styles-module--plain_text_section_description--1aqt7";
export var plain_text_section_front = "styles-module--plain_text_section_front--29SzV";
export var prefooter_section_back = "styles-module--prefooter_section_back--vu7JS";
export var prefooter_section_ctas_container = "styles-module--prefooter_section_ctas_container--XPxZQ";
export var prefooter_section_front = "styles-module--prefooter_section_front--HxMVR";
export var prefooter_section_title = "styles-module--prefooter_section_title--A9Hn9";
export var promoted_resource_card = "styles-module--promoted_resource_card--WM5LM";
export var promoted_resource_card_author_image = "styles-module--promoted_resource_card_author_image--1J0fK";
export var promoted_resource_card_author_name = "styles-module--promoted_resource_card_author_name--PGwEJ";
export var promoted_resource_card_bottom_section = "styles-module--promoted_resource_card_bottom_section--Os5Kf";
export var promoted_resource_card_image = "styles-module--promoted_resource_card_image--LzZIa";
export var promoted_resource_card_tag = "styles-module--promoted_resource_card_tag--G7mZm";
export var promoted_resource_card_title = "styles-module--promoted_resource_card_title--LjBjJ";
export var promoted_resource_card_top_section = "styles-module--promoted_resource_card_top_section--Esltw";
export var promoted_resources_card_grid = "styles-module--promoted_resources_card_grid--1z1c2";
export var promoted_resources_section_back = "styles-module--promoted_resources_section_back--jwim2";
export var promoted_resources_section_front = "styles-module--promoted_resources_section_front--58vj+";
export var section_title = "styles-module--section_title--xuEgC";
export var video_section_back = "styles-module--video_section_back--FN3UR";
export var video_section_front = "styles-module--video_section_front--3VSxc";
export var wrapper = "styles-module--wrapper--QvUSk";