import React from 'react'
import kafkaReplicationImage from '../../../resources/images/hanzo/kafka-replication/kafka-replication.webp'
import stateOfKafkaReplicationImage from '../../../resources/images/hanzo/kafka-replication/state-of-kafka-replication.webp'
import lenses6PanoptesImage from '../../../resources/images/hanzo/kafka-replication/lenses-6-0-panoptes.webp'
import pressReleaseImage from '../../../resources/images/hanzo/kafka-replication/press-release.webp'
import andrewStevensonImage from '../../../resources/images/hanzo/kafka-replication/andrew-stevenson.webp'
import lensesAvatarImage from '../../../resources/images/hanzo/kafka-replication/lenses-avatar.webp'
import lensesK2KVideoFeature from '../../../resources/images/hanzo/kafka-replication/lenses-k2k.mp4'
import lensesK2KVideoFeatureOptimized from '../../../resources/images/hanzo/kafka-replication/lenses-k2k.webm'
import { K2KIcon } from './k2k-icon'
import { DemoIcon } from '../../lib/components/basics/icons'

export const heroProps = {
    title: (
        <>
            Confident Kafka replication,
            <br />
            complete autonomy
        </>
    ),
    description: (
        <p>
            Kafka replication meant choosing between cloud providers and clunky operations. Until
            now.
        </p>
    ),
    primaryCtaLabel: 'See it in action',
    primaryCtaLink: '/request-demo/?product=k2k',
}

export const leftRightColumn = {
    title: 'Kafka replication needs to change',
    description: (
        <>
            <p>
                Moving data between Kafka clusters should be simple but it is not. There are a few
                challenges with existing solutions. Engineers need to:
            </p>
            <ul className='list-disc list-outside !mx-0 !my-4 !pl-4'>
                <li>Keep data consistent across different Kafka providers</li>
                <li>Control growing costs as data volumes expand and multiply</li>
                <li>Govern and secure data across multiple locations</li>
                <li>Automate data movement with code.</li>
            </ul>
            <br />
            <p>
                Most tools only solve part of the problem, and teams waste time switching between
                different solutions. More companies are using multiple clouds, and need one tool to
                unify data sharing across different domains.
            </p>
        </>
    ),
    ctas: [
        {
            text: 'Read more',
            href: '/blog/2025/02/state-of-kafka-replication',
        },
    ],
}

export const leftRightColumnImage = {
    imgSrc: kafkaReplicationImage,
    altText: 'Kafka replication',
}

export const plainTextSectionTitle = (
    <>
        Lenses K2K: universal, user-friendly
        <br />
        Kafka replication
    </>
)

export const plainTextSectionDescription =
    'Lenses K2K is a new Kafka replicator that lets you share Kafka data between any cloud and platform. No vendor lock-in. No technical barriers.'

export const plainTextSectionBlock1Props = {
    title: <div>Vendor agnostic</div>,
    textBlocks: [
        <div key='1'>
            <p>
                Built for enterprises to replicate streaming data between vendors and domains. Run
                Kafka anywhere – and share data as you want.
            </p>
        </div>,
    ],
}

export const plainTextSectionBlock2Props = {
    title: <div>User-friendly</div>,
    textBlocks: [
        <div key='1'>
            <p>
                From full data transformation support to bounded replication, alerting, and
                auto-scaling. Enjoy a fine-grained governance model that works across different
                clusters.
            </p>
        </div>,
    ],
}

export const plainTextSectionBlock3Props = {
    title: <div>Technical support</div>,
    textBlocks: [
        <div key='1'>
            <p>
                Relies on native Kubernetes infrastructure. No Kafka Connect required. Lenses K2K
                will be included in the Lenses Multi-Kafka Developer Experience, available with
                enterprise support.
            </p>
        </div>,
    ],
}

export const videoSectionProps = {
    title: 'See the Kafka replicator in action',
    description:
        'Replicate your topic from one Kafka to another with a click. Define your replication in a simple YAML. Lenses does the rest.',
    video: {
        src: lensesK2KVideoFeature,
        srcOptimized: lensesK2KVideoFeatureOptimized,
    },
}

export const featuredResourcesBlock = {
    title: 'Featured Resources',
}

export const promotedResourcesSectionItems = [
    {
        featuredImage: stateOfKafkaReplicationImage,
        tag: 'Blog',
        title: 'The state of Kafka replication',
        link: '/blog/2025/02/state-of-kafka-replication',
        author: {
            image: andrewStevensonImage,
            name: 'Andrew Stevenson',
        },
    },
    {
        featuredImage: pressReleaseImage,
        tag: 'Press release',
        title: 'Lenses.io introduces new streaming data replicator',
        link: '/blog/2025/02/introducing-streaming-data-replicator/',
        author: {
            image: lensesAvatarImage,
            name: 'Lenses.io',
        },
    },
    {
        featuredImage: lenses6PanoptesImage,
        tag: 'Blog',
        title: 'Introducing Lenses 6.0 Panoptes',
        link: '/blog/2024/11/lenses-6-panoptes-announcement/',
        author: {
            image: andrewStevensonImage,
            name: 'Andrew Stevenson',
        },
    },
]

export const reviewsPreFooter = [
    {
        review: 'Lenses is amazing at providing insights into the Kafka topics',
        author: 'Alexandru-Florian A',
    },
    {
        review: 'The Lenses product is amazing and I am positive it has drastically increased our development times using Kafka.',
        author: 'Matthew G.',
    },
    {
        review: 'Beautiful, responsive UI for kafka overview and with lenses SQL for kafka topics browsing',
        author: 'Ivan V.',
    },
    {
        review: "Lenses provides a quick inside view of all your data flow. There's not much to dislike.",
        author: 'Kyriakos P.',
    },
    {
        review: 'Lenses is an intuitive tool that provides great observability and management features for our streaming',
        author: 'Anonymous',
    },
    {
        review: 'A very respectable add-on for enterprise-grade Kafka clusters!',
        author: 'Donny F.',
    },
    { review: 'Invaluable tool for anyone using Kafka', author: 'Michael G.' },
    {
        review: `Very complete tool to browse and understand what's happening inside your clusters`,
        author: 'George F.',
    },
]

export const prefooterTitle = 'Get a private preview'

export const prefooterCtaLabel = 'See it in action'
export const prefooterCtaLink = '/request-demo/?product=k2k'

export const twoColumnCtaSectionProps = [
    {
        title: 'Join the webinar',
        type: 'primary' as const,
        leadingText:
            'Join our virtual session to learn what you can do with Lenses K2K, and see how it works.',
        buttonText: 'Register now',
        href: '/kafka-replicator-webinar/',
        icon: <K2KIcon key='k2kIcon' />,
    },
    {
        title: 'Get a private preview',
        type: 'secondary' as const,
        leadingText:
            'Get a 1 on 1 demo of Lenses K2K to see how it can work with your Kafka environment.',
        buttonText: 'Schedule',
        href: '/request-demo/?product=k2k',
        icon: <DemoIcon key='demoIcon' />,
    },
]
